




























































































































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import I18nFormattedDateTime from "@/components/i18n/I18nFormattedDateTime.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Icon from "@/components/Icon.vue"
import Loading from "@/components/Loading.vue"
import { TypedComponentProps } from "@/vue-props-types"
import { Props } from "./types"
import TextField from "@/components/textFields/TextField.vue"
import TextArea from "@/components/textAreas/TextArea.vue"
import ConfirmDialogWithCheckbox from "@/components/dialogs/ConfirmDialogWithCheckbox.vue"
import WithBackPageLayout from "@/layouts/WithBackPageLayout.vue"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import MemoTemplateCard from "./MemoTempleteCard.vue"
import { i18nContainer } from "@/containers/I18nContainer"
import { MeetingStructure } from "@/models/Meeting"
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue"
import MemoTemplateMenuCard from "./MemoTemplateMenuCard.vue"
import MemoTemplateDialog, {
  DialogType,
  MemoInfo,
} from "./MemoTemplateDialog.vue"
import { SanitizeHtml } from "@/utilities/SanitizeHtml"
import MemoTemplate from "@/models/MemoTemplate"
import { projectsContainer } from "@/containers/ProjectsContainer"

interface State {
  isLoading: boolean
  isOpenTemplateDialog: boolean
  isOpenConfirmDialog: boolean
  meetingStructure: MeetingStructure
  commonMemoTemplates: Array<MemoTemplate> | null
  customMemoTemplates: Array<MemoTemplate> | null
  memoInfoForDialog: MemoInfo
}

const propsOptions = {
  id: {
    type: String,
    default: "",
  },
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      I18nFormattedDateTime,
      I18nFormattedMessage,
      Icon,
      Loading,
      TextField,
      TextArea,
      ConfirmDialogWithCheckbox,
      WithBackPageLayout,
      MemoTemplateCard,
      ConfirmDialog,
      MemoTemplateMenuCard,
      MemoTemplateDialog,
    },
    setup(props) {
      const {
        state: meetingState,
        updateMeetingStructureAsync,
        getMemosAsync,
        createMemosAsync,
        updateMemosAsync,
        deleteMemosAsync,
      } = meetingContainer.useContainer()

      const { getContext } = teamsContextContainer.useContainer()
      const { getProjectAsync } = projectsContainer.useContainer()

      const state = reactive<State>({
        isLoading: false,
        isOpenConfirmDialog: false,
        isOpenTemplateDialog: false,
        meetingStructure: new MeetingStructure(
          meetingState.meetings.value?.find(v => v.id === props.id) ??
            ({} as MeetingStructure)
        ),
        memoInfoForDialog: {
          id: "",
          name: "",
          body: "",
          type: DialogType.New,
        },
        commonMemoTemplates: null,
        customMemoTemplates: null,
      })
      onMounted(async () => {
        const memoTemplates = await getMemosAsync(props.id)
        state.commonMemoTemplates = memoTemplates.filter(m => m.type === 0)
        state.customMemoTemplates = memoTemplates.filter(m => m.type === 1)
      })
      watch(
        () => meetingState.meetings.value,
        meetingValues => {
          if (meetingValues) {
            state.meetingStructure = new MeetingStructure(
              meetingValues.find(v => v.id === props.id) ??
                ({} as MeetingStructure)
            )
          }
        },
        {
          deep: true,
        }
      )

      watch(
        () => meetingState.templates.value,
        memoTemplates => {
          if (memoTemplates) {
            state.commonMemoTemplates = memoTemplates.filter(m => m.type === 0)
            state.customMemoTemplates = memoTemplates.filter(m => m.type === 1)
          }
        }
      )

      const { formatMessage } = i18nContainer.useContainer()

      const openTemplateDialog = (type: DialogType, id?: string) => {
        state.isOpenTemplateDialog = true

        state.memoInfoForDialog.type = type
        state.memoInfoForDialog.id = id ?? ""

        if (type === DialogType.Custom && state.customMemoTemplates) {
          const targetTemplate = state.customMemoTemplates.find(
            m => m.id === id
          )
          state.memoInfoForDialog.body = targetTemplate?.body ?? ""
          state.memoInfoForDialog.name = targetTemplate?.name ?? ""
        } else if (type === DialogType.Common && state.commonMemoTemplates) {
          const targetTemplate = state.commonMemoTemplates.find(
            m => m.id === id
          )
          state.memoInfoForDialog.body = targetTemplate?.body ?? ""
          state.memoInfoForDialog.name = targetTemplate?.name ?? ""
        } else {
          state.memoInfoForDialog.body = ""
          state.memoInfoForDialog.name = ""
        }
      }

      const changeTemplateActiveState = async (id: string) => {
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        state.meetingStructure.selectedMemoTemplateId = id
        await updateMeetingStructureAsync(
          project.siteId,
          context.entityId,
          props.id,
          state.meetingStructure
        )
      }

      const clearTemplate = async () => {
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        state.meetingStructure.selectedMemoTemplateId = ""
        await updateMeetingStructureAsync(
          project.siteId,
          context.entityId,
          props.id,
          state.meetingStructure
        )
      }

      const onTemplateDialogOk = async (memoInfo: MemoInfo) => {
        state.isLoading = true
        if (memoInfo.type === DialogType.New) {
          await createMemosAsync(
            props.id,
            new MemoTemplate({
              name: memoInfo.name,
              body: memoInfo.body,
            } as MemoTemplate)
          )
        } else if (memoInfo.type === DialogType.Custom) {
          await updateMemosAsync(
            props.id,
            memoInfo.id,
            new MemoTemplate({
              name: memoInfo.name,
              body: memoInfo.body,
            } as MemoTemplate)
          )
        }
        state.isLoading = false
        state.isOpenTemplateDialog = false
      }

      const onDeleteConfirmDialogOk = async (memoInfo: MemoInfo) => {
        state.isLoading = true

        await deleteMemosAsync(props.id, memoInfo.id)
        state.isLoading = false
        state.isOpenTemplateDialog = false
      }

      const onTemplateDialogCancel = () => {
        if (state.memoInfoForDialog.type === DialogType.Common) {
          state.isOpenTemplateDialog = false
        } else {
          state.isOpenConfirmDialog = true
        }
      }

      const onConfirmDialogOk = () => {
        state.isOpenConfirmDialog = false
        state.isOpenTemplateDialog = false
      }

      const onConfirmDialogCancel = () => {
        state.isOpenConfirmDialog = false
      }

      const sanitizeHTML = (html: string) => {
        return SanitizeHtml(html)
      }

      return {
        state,
        i18nFormattedMessage: formatMessage,
        openTemplateDialog,
        onTemplateDialogOk,
        onTemplateDialogCancel,
        onDeleteConfirmDialogOk,
        onConfirmDialogOk,
        onConfirmDialogCancel,
        changeTemplateActiveState,
        clearTemplate,
        DialogType,
        sanitizeHTML,
      }
    },
  }
)
